<template>
  <v-container class="py-16">
    <div
      class="d-flex flex-row justify-start"
      v-if="collections != undefined && collections != null"
    >
      <TripCardSimple
        class="mr-10"
        v-for="collect in collections"
        :key="collect.id"
        :tripdata="$attrs.tripdata"
      ></TripCardSimple>
    </div>
  </v-container>
</template>

  </v-container>
</template>

<script>
import { db } from "@/firebaseConfig.js";
import TripCardSimple from "./TripCardSimple.vue";
export default {
  components: {
    TripCardSimple,
  },
  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
    };
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      autoUpdate: true,

      isUpdating: false,
    };
  },
  firestore: {
    collections: db.collection("restaurant"),
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  methods: {
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
    // _goDetail() {
    //   console.log(this.$attrs.tripdata);
    //   //this.$router.push({ name: "MyTrips", params: { tripId: 11 } });
    //   this.dialog = true;
    // },
  },
};
</script>