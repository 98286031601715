<template>
  <v-card max-width="200" class="my-10" v-on="$listeners" elevation="2">
    <v-carousel
      cycle
      height="200"
      hide-delimiters
      :show-arrows="false"
    >
      <v-carousel-item
        v-for="(image, imageIndex) in $attrs.location.images"
        :key="imageIndex"
        :src="image.fileUrl"
      >
      </v-carousel-item>
    </v-carousel>
    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-bookmark</v-icon>
      </v-btn> -->

      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="success">mdi-plus-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(dday, dindex) in this.$attrs.planned"
            :key="dindex"
            link
          >
            <v-list-item-title
              v-text="'Day-' + dday.index"
              @click="_selectDay(dday)"
            ></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-card>
</template>
<script>
import { db } from "@/firebaseConfig.js";
export default {
  data() {
    return {};
  },
  computed: {},
  created(){
  },
  methods: {
    async _selectDay(dday) {
      console.log(dday.ref.path);
      console.log(this.$attrs.location);
      console.log(this.$attrs.location.locRef.path);
      console.log(this.$attrs.location.ref.path);

      var newLocation = {
        locRef: this.$attrs.location.locRef,
        savedLocationRef: this.$attrs.location.ref,
        name: this.$attrs.location.name,
        gpsfield: this.$attrs.location.gpsfield,
      };

      var xxx = await this.$attrs.location.locRef.get();
      if (!xxx.empty) {
        console.log(xxx.data().images);
        newLocation.images = xxx.data().images;
      }

      dday.locations.push(newLocation);
      dday.ref.update(dday);
    },
  },
};
</script>